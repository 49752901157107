/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, Link } from 'react-router-dom'

import {
  hideMessage,
  forgotPassword,
  forgotPasswordSubmit,
} from '../appRedux/actions'

import IntlMessages from '../util/IntlMessages'
import CircularProgress from '../components/CircularProgress'
const ForgotPassword = (): any => {
  const lastLocation = useLocation()
  const dispatch = useDispatch()
  const [username, setUsername] = useState<any>('')
  const [code, setCode] = useState<any>('')
  const [newPass, setNewpass] = useState<any>('')
  const [forgotSubmit, setForgotSubmit] = useState(false)
  const { loader, alertMessage, showMessage, authUser, link, sendCode } =
    useSelector((state: RootStateOrAny) => state.auth)
  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const history = useHistory()

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage())
      }, 50)
    }
    if (authUser !== null) {
      if (lastLocation?.state === undefined) {
        history.push('/')
      }
    }
  })
  const onFinishFailed = (errorInfo: any) => {
    console.log('112312', errorInfo)
  }
  const onFinish = () => {
    dispatch(forgotPassword(username))
    form2.setFieldsValue({ email: username })
  }

  useEffect(() => {
    if (sendCode) {
      setForgotSubmit(true)
    }
  }, [sendCode])

  useEffect(() => {
    if (link) history.push('/signin')
  }, [link])

  const onFinishSubmit = () => {
    dispatch(forgotPasswordSubmit(username, code, newPass))
  }
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.forgotPassword" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.byForgot" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
          </div>
          {forgotSubmit === false ? (
            <div className="gx-app-login-content">
              <Form
                initialValues={{ remember: true }}
                name="basic"
                form={form1}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="gx-signin-form gx-form-row0"
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'The input is not valid E-mail!',
                      type: 'email',
                    },
                  ]}
                  name="email"
                >
                  <Input
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Please enter your Email!"
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.send" />
                  </Button>
                  <span>
                    <IntlMessages id="app.userAuth.or" />
                  </span>{' '}
                  <Link to="/signin">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Link>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div className="gx-app-login-content">
              <Form
                initialValues={{ remember: true }}
                name="basic"
                form={form2}
                onFinish={onFinishSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please input your email!',
                    },
                  ]}
                  name="email"
                >
                  <Input
                    placeholder="Email"
                    readOnly
                    // onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: 'Please input verification code!',
                    },
                  ]}
                >
                  <Input
                    placeholder="Verification Code"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  rules={[
                    { required: true, message: 'Please input new Password!' },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="New Password"
                    onChange={(e) => setNewpass(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.confirm" />
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
