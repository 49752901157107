import {
  GET_BY_ID_CLASS,
  GET_BY_ID_CLASS_SUCCESS,
  GET_BY_ID_CLASS_ERROR,
  CREATE_CLASS_SUCCESS,
  CREATE_CLASS_ERROR,
  UPDATE_CLASS_SUCCESS,
  UPDATE_CLASS_ERROR,
  GET_ALL_CLASS_BY_ACCOUNT_ID,
  DELETE_CLASS_SUCCESS,
  DELETE_CLASS_ERROR,
  GET_LIST_HEDGEFUND_CLASS,
  GET_LIST_HEDGEFUND_CLASS_SUCCESS,
  GET_ALL_CLASS_BY_HEDGEFUND_ID,
} from '../../constants/ActionTypes'
import { ClassItem } from '../../dto'
import { ClassStateDto } from './stateDtos'
interface ActionProps {
  id: number
  type: string
  data: ClassItem[]
  error: string
  loading: boolean
  payload: ClassItem
}
const INIT_STATE: ClassStateDto = {
  recordClass: null,
  ClassList: [],
  CreateAlert: true,
  UpdateAlert: true,
  id: 0,
}
const ClassReducer = (
  state = INIT_STATE,
  action: ActionProps
): ClassStateDto => {
  switch (action.type) {
    case GET_LIST_HEDGEFUND_CLASS: {
      return {
        ...state,
      }
    }
    case GET_LIST_HEDGEFUND_CLASS_SUCCESS: {
      return {
        ...state,
        ClassList: action.data,
      }
    }
    case GET_BY_ID_CLASS: {
      return {
        ...state,
      }
    }
    case GET_BY_ID_CLASS_SUCCESS: {
      return {
        ...state,
        recordClass: action.payload,
        id: action.id,
      }
    }
    case GET_BY_ID_CLASS_ERROR: {
      return {
        ...state,
      }
    }
    case CREATE_CLASS_SUCCESS: {
      const newList = [...state.ClassList]
      newList.push(action.payload)
      return {
        ...state,
        ClassList: newList,
        CreateAlert: true,
      }
    }
    case CREATE_CLASS_ERROR: {
      return {
        ...state,
      }
    }
    case UPDATE_CLASS_SUCCESS: {
      const newList = state.ClassList.map(function (item) {
        return item.id == action.payload.id ? action.payload : item
      })
      return {
        ...state,
        ClassList: newList,
        UpdateAlert: true,
      }
    }
    case UPDATE_CLASS_ERROR: {
      return {
        ...state,
      }
    }
    case GET_ALL_CLASS_BY_ACCOUNT_ID: {
      return {
        ...state,
        ClassList: action.data,
      }
    }
    case GET_ALL_CLASS_BY_HEDGEFUND_ID: {
      return {
        ...state,
        ClassList: action.data,
      }
    }
    case DELETE_CLASS_SUCCESS: {
      const newList = state.ClassList.filter(
        (item) => item.id !== action.payload
      )
      return {
        ...state,
        ClassList: newList,
      }
    }
    case DELETE_CLASS_ERROR: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
export default ClassReducer
