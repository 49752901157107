/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { Breadcrumb } from 'antd'
import { Route, Switch } from 'react-router-dom'
import router from './router'
import queryString from 'query-string'
interface Props {
  onData: (data: string) => void
}
export const Breadcrumbs = (propsLink: Props) => {
  const params = queryString.parse(location.search)

  const capatilize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)
  return (
    <Route>
      <Switch>
        {router.map(({ path }, key) => (
          <Route
            exact
            path={path}
            key={'route' + key}
            render={(props) => {
              const crumbs = router
                .filter(({ path }) => props.match.path.includes(path))
                .map(({ path, ...name }) => ({
                  path: Object.keys(props.match.params).length
                    ? Object.keys(props.match.params).reduce(
                        (path, param) =>
                          path.replace(
                            `:${param}`,
                            props.match.params[param] as string
                          ),
                        path
                      )
                    : path,
                  ...name,
                }))
              const lastIndex = crumbs.length - 2
              const item = crumbs[lastIndex]
              propsLink.onData(
                `${item?.path}${params.page ? `?${`page=${params.page}`}` : ''}`
              )
              return (
                <>
                  <Breadcrumb separator="/">
                    {crumbs.map(({ name, index }) => {
                      return (
                        <Breadcrumb.Item key={'Breadcrumb' + index}>
                          {capatilize(name)}
                        </Breadcrumb.Item>
                      )
                    })}
                  </Breadcrumb>
                </>
              )
            }}
          ></Route>
        ))}
      </Switch>
    </Route>
  )
}
export const Title = () => {
  // const capatilize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)
  return (
    <Route>
      <Switch>
        {router.map(({ path }, key) => (
          <Route
            exact
            path={path}
            key={'route' + key}
            render={(props) => {
              const crumbs = router
                .filter(({ path }) => props.match.path.includes(path))
                .map(({ path, ...rest }) => ({
                  path: Object.keys(props.match.params).length
                    ? Object.keys(props.match.params).reduce(
                        (path, param) =>
                          path.replace(
                            `:${param}`,
                            props.match.params[param] as string
                          ),
                        path
                      )
                    : path,
                  ...rest,
                }))
              return (
                <>
                  <div>{crumbs.slice(-1)[0].name}</div>
                </>
              )
            }}
          ></Route>
        ))}
      </Switch>
    </Route>
  )
}
