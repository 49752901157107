const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'chinese',
    locale: 'zh',
    name: 'Chinese',
    icon: 'cn'
  },
  {
    languageId: 'japan',
    locale: 'jp',
    name: 'Japan',
    icon: 'jp'
  },
];
export default languageData;
