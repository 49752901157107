import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import MasterManagement from "../masterManagement/index";
import HedgeFund from "../hedgefund";
// import HedgeFundNAV from "../hedgefund_nav/index";
import Account from "../account/index";
import Analyze from "../analyze/index";
import asyncComponent from "../../util/asyncComponent";
import Profile from "../profile/index";
import Administration from "../administration";
import DashBoards from "../../components/DashBoards"
import AnalyticalTools from "../analyticalTools";
interface iProps {
  match: {
    url: string
  }
}
const Main = ({ match }: iProps): JSX.Element => (
  <Switch>
    <Route path={`${match.url}/dashboards`} component={DashBoards} />
    <Route path={`${match.url}/master`} component={MasterManagement} />
    <Route path={`${match.url}/hf`} component={HedgeFund} />
    {/* <Route path={`${match.url}/hedgefund`} component={HedgeFundNAV} /> */}
    <Route path={`${match.url}/analyze`} component={Analyze} />
    <Route path={`${match.url}/acct`} component={Account} />
    <Route path={`${match.url}/profile`} component={Profile} />
    <Route path={`${match.url}/administration`} component={Administration} />
    <Route path={`${match.url}/analytical`} component={AnalyticalTools} />
    <Route path={`${match.url}/algolia`} component={asyncComponent(() => import('../algolia'))} />
  </Switch>
);

export default Main;
