import { GET_LIST_ACCOUNT_TRANSACTION } from "../../constants/ActionTypes";
import { AccountTransactionStateDto } from './stateDtos';
interface ActionProps {
    type: string,
    payload: AccountTransactionStateDto
}

const INIT_STATE : AccountTransactionStateDto = {
    acTransactionList:[
     
    ]
};
const AccountTransactionReducer = (state = INIT_STATE, action: ActionProps ):AccountTransactionStateDto => {
    switch (action.type) {
        case GET_LIST_ACCOUNT_TRANSACTION:
          {
            return {
              ...state
            }
          }
        default:
          return state;
      }
}
export default AccountTransactionReducer