/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  GET_LIST_STRATEGY,
  GET_LIST_STRATEGY_DEFAULT,
  GET_LIST_STRATEGY_ERROR,
  GET_LIST_STRATEGY_SUCCESS,
} from '../../constants/ActionTypes'
import { Dispatch } from 'redux'
import { STATUS_CODE_SUCCESS } from '../../constants/StatusCode'
import { axiosInstance } from '../../core/axios'
import { configHeader } from '../../core/token'
import { LegacyStrategyApi } from '../../client-axios/api';
export const getListStrategy = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_LIST_STRATEGY })
  const strategyApi = new LegacyStrategyApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  strategyApi
    .findAllWithStrategies()
    .then((res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({ type: GET_LIST_STRATEGY_SUCCESS, data: res.data })
        dispatch({ type: GET_LIST_STRATEGY_DEFAULT, data: res.data })
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_LIST_STRATEGY_ERROR,
        error: error?.response?.data?.message,
      })
    })
}
export const saveListStrategy = (payload: unknown) => {
  return {
    type: GET_LIST_STRATEGY,
    payload,
  }
}
