import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'

interface iProps {
  match: {
    url: string
  }
}

const Administration = ({ match }: iProps): JSX.Element => (
  <Switch>
    {' '}
    <Route
      exact
      path={`${match.url}/users`}
      component={asyncComponent(
        () => import('../../components/AdministrationMenu/userManagement')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/marketData`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AdministrationMenu/Admin_MarketData/MarketDataViewer'
          )
      )}
    />
  </Switch>
)
export default Administration
