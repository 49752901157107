/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import {Route, Switch} from "react-router-dom";
import Amplify from 'aws-amplify';
import "./assets/vendors/style";
import configureStore, { history } from './appRedux/store';
// import "./firebase/firebase";
import App from "./containers/App/index";
import AmplifyConfig from './util/aws-exports';
import "./assets/fontawesome";

const store = configureStore(/* provide initial state if any */);

Amplify.configure(AmplifyConfig);

const NextApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>;


export default NextApp;
