import {
  GET_LIST_CITY,
  GET_LIST_CITY_SUCCESS,
  GET_LIST_CITY_ERROR,
} from '../../constants/ActionTypes'
import { CityItem } from '../../dto'
import { CityStateDto } from './stateDtos'
interface ActionProps {
  type: string
  data: CityItem[]
}
const INIT_STATE = {
  cityList: [],
}
const CityReducer = (state = INIT_STATE, action: ActionProps): CityStateDto => {
  switch (action.type) {
    case GET_LIST_CITY: {
      return {
        ...state,
      }
    }
    case GET_LIST_CITY_SUCCESS: {
      return {
        ...state,
        cityList: action.data,
      }
      }
      case GET_LIST_CITY_ERROR: {
        return {
          ...state,
        }
      }
      default:
          return state;
  }
}
export default CityReducer
