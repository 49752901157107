import {
  GET_BY_ID_HF_HISTORICAL,
  GET_BY_ID_HF_HISTORICAL_SUCCESS,
  GET_BY_ID_HEDGEFUND_ERROR,
  UPDATE_BULK_HF_HISTORICAL,
  UPDATE_BULK_HF_HISTORICAL_SUCCESS,
  UPDATE_BULK_HF_HISTORICAL_ERROR,
} from '../../constants/ActionTypes'
import { HFHistoricalItem } from '../../dto'
import { HFHistoricalStateDto } from './stateDtos'
interface ActionProps {
  id: number
  type: string
  data: HFHistoricalItem[]
  error: string
  loading: boolean
  payload: HFHistoricalItem
}
const INIT_STATE: HFHistoricalStateDto = {
  success: false,
  recordHFHistorical: [],
  HistoricalList: [],
  CreateAlert: true,
  UpdateAlert: true,
  id: 0,
}
const HFHistoricalReducer = (
  state = INIT_STATE,
  action: ActionProps
): HFHistoricalStateDto => {
  switch (action.type) {
    case GET_BY_ID_HF_HISTORICAL: {
      return {
        ...state,
      }
    }
    case GET_BY_ID_HF_HISTORICAL_SUCCESS: {
      return {
        ...state,
        recordHFHistorical: action.data,

        id: action.id,
      }
    }
    case GET_BY_ID_HEDGEFUND_ERROR: {
      return {
        ...state,
        success: false,
      }
    }
    //   case CREATE_ACCOUNT_CLASS_SUCCESS: {
    //     const newList = [...state.ClassList]
    //     newList.push(action.payload)
    //     return {
    //       ...state,
    //       ClassList: newList,
    //       CreateAlert: true
    //     }
    //   }
    //   case CREATE_ACCOUNT_CLASS_ERROR: {
    //     return {
    //       ...state
    //     }
    //   }
    case UPDATE_BULK_HF_HISTORICAL: {
      return {
        ...state,
      }
    }
    case UPDATE_BULK_HF_HISTORICAL_SUCCESS: {
      const newList = state.HistoricalList.map(function (item) {
        return item.id == action.payload.id ? action.payload : item
      })
      return {
        ...state,
        success: true,
        HistoricalList: newList,
      }
    }
    case UPDATE_BULK_HF_HISTORICAL_ERROR: {
      return {
        ...state,
        success: false,
      }
    }
    //   case DELETE_CLASS_SUCCESS: {
    //     const newList = state.ClassList.filter(item => item.id !== action.payload)
    //     return {
    //       ...state,
    //       ClassList: newList
    //     }
    //   }
    default:
      return state
  }
}
export default HFHistoricalReducer
