import React from 'react'
import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { Drawer, Layout, Divider } from 'antd'
import CustomScrollbars from '../../util/CustomScrollbars'
import SidebarLogo from './SidebarLogo'
import {
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from '../../constants/ThemeSetting'
import IntlMessages from '../../util/IntlMessages'
import { useSelector } from 'react-redux'
import { footerText } from '../../util/config'
const { Footer } = Layout
const SubMenu = Menu.SubMenu

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings)
  const pathname = useSelector(({ common }) => common.pathname)

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return 'gx-no-header-submenu-popup'
    }
    return ''
  }
  const selectedKeys = pathname.substr(1)
  const defaultOpenKeys = selectedKeys.split('/')[1]
  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        {/* <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          <AppsNavigation />
        </div> */}
        <CustomScrollbars className="gx-layout-sider-scrollbar gx-layout-sider-scrollbar-custome">
          <Menu
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
            defaultOpenKeys={[
              'hf',
              'acct',
              'master',
              'dataManagement',
              'administration',
              'Analyze',
              'analyticalTool',
            ]}
          >
            <Menu.Item key="main/dashboards">
              <Link to="/main/dashboards">
                <i className="icon icon-home" />
                <span>
                  <IntlMessages id="sidebar.dashboard" />
                </span>
              </Link>
            </Menu.Item>

            <SubMenu
              key="hf"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {' '}
                  <i className="icon icon-widgets" />
                  <span>
                    <IntlMessages id="sidebar.hedgeFund" />
                  </span>
                </span>
              }
            >
              <Menu.Item key="main/hf/return">
                <Link to="/main/hf/return">
                  {/* <i className="icon icon-apps" /> */}
                  <span>
                    <IntlMessages id="sidebar.hedgeFund.return" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/hf/nav">
                <Link to="/main/hf/nav">
                  {/* <i className="icon icon-card" /> */}
                  <span>
                    <IntlMessages id="sidebar.hedgeFund.nav" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/hf/port">
                <Link to="/main/hf/port">
                  {/* <i className="icon icon-crypto icon-navigation" /> */}
                  <span>
                    <IntlMessages id="sidebar.hedgeFund.portfolioManagement" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/hf/dataDuplication">
                <Link to="/main/hf/dataDuplication">
                  {/* <i className="icon icon-copy" /> */}
                  <span>
                    <IntlMessages id="sidebar.dataManagement.dataDuplication" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/hf/doubleCheck">
                <Link to="/main/hf/doubleCheck">
                  {/* <i className="icon icon-crypto icon-navigation" /> */}
                  <span>
                    <IntlMessages id="sidebar.hedgeFund.doubleCheck" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/hf/aum_exposure">
                <Link to="/main/hf/aum_exposure">
                  {/* <i className="icon icon-crypto icon-navigation" /> */}
                  <span>
                    <IntlMessages id="sidebar.hedgeFund.aum.exposure" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="acct"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {' '}
                  <i className="icon icon-apps" />
                  <span>
                    <IntlMessages id="sidebar.account" />
                  </span>
                </span>
              }
            >
              <Menu.Item key="main/acct/nav">
                <Link to="/main/acct/nav">
                  {/* <i className="icon icon-crypto" /> */}
                  <span>
                    <IntlMessages id="sidebar.account.nav" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/acct/txn">
                <Link to="/main/acct/txn">
                  {/* <i className="icon icon-crypto" /> */}
                  <span>
                    <IntlMessages id="sidebar.account.investorTransaction" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/acct/dataDuplication">
                <Link to="/main/acct/dataDuplication">
                  {/* <i className="icon icon-copy" /> */}
                  <span>
                    <IntlMessages id="sidebar.dataManagement.dataDuplication" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/acct/doubleCheck">
                <Link to="/main/acct/doubleCheck">
                  {/* <i className="icon icon-crypto icon-navigation" /> */}
                  <span>
                    <IntlMessages id="sidebar.hedgeFund.doubleCheck" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="master"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {' '}
                  <i className="icon icon-card" />
                  <span>
                    <IntlMessages id="sidebar.masterManagement" />
                  </span>
                </span>
              }
            >
              <Menu.Item key="main/master/hf">
                <Link to="/main/master/hf">
                  {/* <i className="icon icon-crypto" /> */}
                  <span>
                    <IntlMessages id="sidebar.masterManagement.hedgeFund" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/master/acct">
                <Link to="/main/master/acct">
                  {/* <i className="icon icon-crypto" /> */}
                  <span>
                    <IntlMessages id="sidebar.masterManagement.account" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/master/investor">
                <Link to="/main/master/investor">
                  {/* <i className="icon icon-crypto" /> */}
                  <span>
                    <IntlMessages id="sidebar.masterManagement.investor" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/master/organization">
                <Link to="/main/master/organization">
                  {/* <i className="icon icon-crypto" /> */}
                  <span>
                    <IntlMessages id="sidebar.masterManagement.organization" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="Analyze"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  <i className="icon icon-schedule" />
                  <span>
                    <IntlMessages id="sidebar.analyze" />
                  </span>
                </span>
              }
            >
              <Menu.Item key="main/analyze/hfs">
                <Link to="/main/analyze/hfs">
                  <span>
                    <IntlMessages id="head.hedgeFunds" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="main/analyze/accts">
                <Link to="/main/analyze/accts">
                  {/* <i className="icon icon-home" /> */}
                  <span>
                    <IntlMessages id="head.accounts" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="main/analyze/investors">
                <Link to="/main/analyze/investors">
                  {/* <i className="icon icon-home" /> */}
                  <span>
                    <IntlMessages id="head.investors" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/analyze/marketData">
                <Link to="/main/analyze/marketData">
                  {/* <i className="icon icon-crypto" /> */}
                  <span>
                    <IntlMessages id="sidebar.dataManagement.marketData" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="analyticalTool"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  <i className="icon icon-stats" />
                  <span>
                    <IntlMessages id="sidebar.analytical.tool" />
                  </span>
                </span>
              }
            >
              {' '}
              <Menu.Item key="main/analytical/allocation_map">
                <Link to="/main/analytical/allocation_map">
                  <span>
                    <IntlMessages id="sidebar.tool.allocation_map" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/analytical/historical_download">
                <Link to="/main/analytical/historical_download">
                  <span>
                    <IntlMessages id="sidebar.tool.historical_download" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/analytical/historical_war">
                <Link to="/main/analytical/historical_war">
                  <span>
                    <IntlMessages id="sidebar.tool.historical_WAR" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/analytical/monitoring_list">
                <Link to="/main/analytical/monitoring_list">
                  <span>
                    <IntlMessages id="sidebar.tool.monitoring_list" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/analytical/monthly_fund_performance">
                <Link to="/main/analytical/monthly_fund_performance">
                  <span>
                    <IntlMessages id="sidebar.tool.monthly_per_loader" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/analytical/mtd_performance_report">
                <Link to="/main/analytical/mtd_performance_report">
                  <span>
                    <IntlMessages id="sidebar.tool.mtd_report" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/analytical/redemption_schedule">
                <Link to="/main/analytical/redemption_schedule">
                  <span>
                    <IntlMessages id="sidebar.tool.redemp_schedule" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/analytical/strategy_allocation_attribution">
                <Link to="/main/analytical/strategy_allocation_attribution">
                  <span>
                    <IntlMessages id="sidebar.tool.strategy_allo_attribution" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/analytical/strategy_allocation_contributions">
                <Link to="/main/analytical/strategy_allocation_contributions">
                  <span>
                    <IntlMessages id="sidebar.tool.strategy_allo_contributions" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/analytical/top_contributor">
                <Link to="/main/analytical/top_contributor">
                  <span>
                    <IntlMessages id="sidebar.tool.top_contributor" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/analytical/war_report">
                <Link to="/main/analytical/war_report">
                  <span>
                    <IntlMessages id="sidebar.tool.war_report" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="administration"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {' '}
                  <i className="icon icon-auth-screen" />
                  <span>
                    <IntlMessages id="sidebar.administrationMenu" />
                  </span>
                </span>
              }
            >
              <Menu.Item key="main/administration/users">
                <Link to="/main/administration/users">
                  {/* <i className="icon icon-copy" /> */}
                  <span>
                    <IntlMessages id="sidebar.administrationMenu.users" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="main/administration/marketData">
                <Link to="/main/administration/marketData">
                  {/* <i className="icon icon-crypto" /> */}
                  <span>
                    <IntlMessages id="sidebar.dataManagement.marketData" />
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
          {!sidebarCollapsed && (
            <Footer className="custom-footer">
              <div className="gx-layout-footer-content">{footerText}</div>
            </Footer>
          )}
        </CustomScrollbars>
      </div>
    </>
  )
}

export default React.memo(SidebarContent)
