import { Configuration } from '../client-axios/configuration'

export const configHeader = (): Configuration => {
  const configHeader:any = new Configuration()
  const token = localStorage.getItem('token')
  configHeader.accessToken = token;

  configHeader.basePath = process.env.REACT_APP_API_URL;
  return configHeader;
}
