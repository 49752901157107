// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const IntlTranslate = (intl: any) => {
  return (key: string) : string => {
    return intl.formatMessage({id: key});
  }
}
export default IntlTranslate;


// src/hocexample/index.js
// import React from 'react';
// import {useIntl, injectIntl} from 'react-intl';
// type TranslateProps = {
//   t: (key: string) => string;
// }
// // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// const IntlTranslate = function<P>( WrappedComponent: React.ComponentType<P & TranslateProps>) {
//   const intl = useIntl();
//   const t = (key: string) => {
//     return intl.formatMessage({id: key});
//   }
//   const ComponentWithExtraInfo = (props: P) => {
//     return <WrappedComponent {...props} t={t} />;
//   };
//   return ComponentWithExtraInfo;
// }
// //export default IntlTranslate;
// export default injectIntl(IntlTranslate);


