import { GET_DETAIL_DATA_HEDGEFUND_INDEX_WITH_TICKER, GET_DETAIL_DATA_HEDGEFUND_INDEX_WITH_TICKER_ERROR, GET_DETAIL_DATA_HEDGEFUND_INDEX_WITH_TICKER_SUCCESS, GET_DETAIL_DATA_WITH_TICKER, GET_DETAIL_DATA_WITH_TICKER_ERROR, GET_DETAIL_DATA_WITH_TICKER_SUCCESS, GET_LIST_HEDGEFUND_INDEX_TICKER, GET_LIST_HEDGEFUND_INDEX_TICKER_ERROR, GET_LIST_HEDGEFUND_INDEX_TICKER_SUCCESS, GET_LIST_INTEREST_RATES_TICKER, GET_LIST_INTEREST_RATES_TICKER_ERROR, GET_LIST_INTEREST_RATES_TICKER_SUCCESS } from "../../../constants/ActionTypes"
import { HedgefundIndexTickerStateDto, IHedgefundIndexTickerWithRawData } from "../stateDtos/MarketData/HedgefundIndexTickerStateDto"

interface ActionProps {
    data: HedgefundIndexTickerStateDto[]
    detailDataHfIndex: IHedgefundIndexTickerWithRawData | null
    type: string
}
const INIT_STATE: HedgefundIndexTickerStateDto = {
    hedgeFundIndexTickerList: [],
    detailDataHfIndex: null
}
const HedgefundIndexTickerReducer = (
    state = INIT_STATE,
    action: ActionProps
) => {
    switch (action.type) {
        case GET_LIST_HEDGEFUND_INDEX_TICKER: {
            return {
                ...state,
                hedgeFundIndexTickerList: [],
            }
        }
        case GET_LIST_HEDGEFUND_INDEX_TICKER_SUCCESS: {
            return {
                ...state,
                hedgeFundIndexTickerList: action.data,
            }
        }
        case GET_LIST_HEDGEFUND_INDEX_TICKER_ERROR: {
            return {
                ...state,
                hedgeFundIndexTickerList: [],
            }
        }
        case GET_DETAIL_DATA_HEDGEFUND_INDEX_WITH_TICKER: {
            return {
                ...state,
                detailDataHfIndex: null,
            }
        }
        case GET_DETAIL_DATA_HEDGEFUND_INDEX_WITH_TICKER_SUCCESS: {
            return {
                ...state,
                detailDataHfIndex: action.detailDataHfIndex,
            }
        }
        case GET_DETAIL_DATA_HEDGEFUND_INDEX_WITH_TICKER_ERROR: {
            return {
                ...state,
                detailDataHfIndex: null,
            }
        }
        default:
            return state
    }
}
export default HedgefundIndexTickerReducer