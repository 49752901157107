/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  GET_BY_ID_SERIES,
  GET_BY_ID_SERIES_SUCCESS,
  GET_BY_ID_SERIES_ERROR,
  CREATE_SERIES_SUCCESS,
  UPDATE_SERIES_ERROR,
  UPDATE_SERIES_SUCCESS,
  DELETE_SERIES,
  DELETE_SERIES_SUCCESS,
  DELETE_SERIES_ERROR,
  GET_LIST_ACCOUNT_SERIES_SUCCESS,
  GET_LIST_ACCOUNT_SERIES_ERROR,
} from '../../../constants/ActionTypes'
import {
  STATUS_CODE_SUCCESS,
  STATUS_CODE_CREATE_SUCCESS,
} from '../../../constants/StatusCode'
import { AccountSeriesApi } from '../../../client-axios/api'
import { axiosInstance } from '../../../core/axios'
import { configHeader } from '../../../core/token'
import { Dispatch } from 'redux'
import { CREATE_SERIES_ERROR } from '../../../constants/ActionTypes'
import { message } from 'antd'

export const getListSeries = () => (dispatch: Dispatch) => {
  const seriesApi = new AccountSeriesApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  return seriesApi
    .findAllWithAccountSeries()
    .then((res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({ type: GET_LIST_ACCOUNT_SERIES_SUCCESS, data: res.data })
      }
      return res?.status
    })
    .catch((error) => {
      dispatch({
        type: GET_LIST_ACCOUNT_SERIES_ERROR,
        error: error.response?.data?.detail?.message,
      })
      return error?.response?.statusCode
    })
}
export const createSeries =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const seriesApi = new AccountSeriesApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return seriesApi
      .createWithAccountSeries(payload)
      .then((res) => {
        if (res.status === STATUS_CODE_CREATE_SUCCESS) {
          dispatch({ type: CREATE_SERIES_SUCCESS, payload: res.data })
        }
        return res?.status
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: CREATE_SERIES_ERROR,
            error: error.response?.data?.detail?.message,
          })
        }
        return error?.response?.statusCode
      })
  }
export const updateSeries =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const seriesApi = new AccountSeriesApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return seriesApi
      .updateWithAccountSeries(payload.id, payload.data)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: UPDATE_SERIES_SUCCESS, payload: res.data })
        }
        return res?.status
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_SERIES_ERROR,
          error: error.response?.data?.detail?.message,
        })
        return error.response?.statusCode
      })
  }
export const deleteSeries = (payload: any) => (dispatch: Dispatch) => {
  const seriesApi = new AccountSeriesApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  dispatch({ type: DELETE_SERIES })
  return seriesApi
    .removeWithAccountSeries(payload.id)
    .then((res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({ type: DELETE_SERIES_SUCCESS, data: res.data })
      }
    })
    .catch((error) => {
      dispatch({
        type: DELETE_SERIES_ERROR,
        error: error.response?.data?.message,
      })
      message.error('Cannot Delete!')
    })
}
export const getSeriesById =
  (payload: { id: any }): unknown =>
  (dispatch: Dispatch) => {
    const seriesApi = new AccountSeriesApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    dispatch({ type: GET_BY_ID_SERIES })
    return seriesApi
      .findOneWithAccountSeries(payload.id)
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: GET_BY_ID_SERIES_SUCCESS, payload: res?.data })
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_BY_ID_SERIES_ERROR,
          error: error.response?.data?.message,
        })
      })
  }
