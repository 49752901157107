import moment from 'moment'
import {
  PAGE_LIST_NUMBER,
  GET_LIST_HEDGE_FUND_RETURN,
  // GET_LIST_HEDGE_FUND_RETURN_SUCCESS,
  GET_LIST_HEDGE_FUND_RETURN_ERROR,
  GET_LIST_HEDGE_FUND_RETURN_DATA_ENTRY,
  GET_LIST_HEDGE_FUND_RETURN_DATA_ENTRY_SUCCESS,
  GET_LIST_HEDGE_FUND_RETURN_SUCCESS,
  GET_LIST_HEDGE_FUND_RETURN_DEFAULT_SUCCESS,
  CREATE_INPUT_RETURN,
  // CREATE_INPUT_RETURN_SUCCESS,
  GET_LIST_HF_HOLDING_LEVEL,
  GET_LIST_HF_HOLDING_LEVEL_SUCCESS,
  BULK_SAVE_INPUT_RETURN,
  BULK_SAVE_INPUT_RETURN_SUCCESS,
  BULK_SAVE_HOLDING_LEVEL,
  BULK_SAVE_HOLDING_LEVEL_SUCCESS,
  GET_VALUE_BASE_DATE_HF_RETURN,
} from '../../constants/ActionTypes'
import { HFReturnItemSave, HFReturnItem } from '../../dto'
import { HoldingLevelItem, InputReturnListItem } from '../../dto/InputReturnDto'
import { HFReturnStateDto } from './stateDtos'
interface ActionProps {
  id: number
  type: string
  error: string
  loading: boolean
  payload: HFReturnItem
  dataBulkSave: HFReturnItemSave
  data: HFReturnItem[]
  dataHolding: HoldingLevelItem[]
  dataInputReturn: InputReturnListItem,
  valueBasedate: string,
  page: number,
}
const INIT_STATE: HFReturnStateDto = {
  id: 0,
  CreateAlert: false,
  UpdateAlert: false,
  HFReturnList: [],
  inputReturnList: [],
  holdinglevelList: [],
  HFReturnDataDefault: [],
  recordDataEntry: null,
  recordNewData: [],
  basedate: moment(
    moment()
      .day(moment()?.day() >= 5 ? 5 : -2)
      .format('YYYY-MM-DD'),
  ),
  loading: true,
  page: 1,
}
const returnReducer = (
  state = INIT_STATE,
  action: ActionProps
): HFReturnStateDto => {
  switch (action.type) {
    case PAGE_LIST_NUMBER: {
      return {
        ...state,
        page: action.page
      }
    }
    case BULK_SAVE_INPUT_RETURN: {
      return {
        ...state,
      }
    }
    case BULK_SAVE_INPUT_RETURN_SUCCESS: {
      return {
        ...state,
        recordNewData: action.dataBulkSave
      }
    }
    case BULK_SAVE_HOLDING_LEVEL: {
      return {
        ...state,
      }
    }
    case BULK_SAVE_HOLDING_LEVEL_SUCCESS: {
      return {
        ...state,
        recordNewData: action.dataBulkSave
      }
    }
    case GET_LIST_HEDGE_FUND_RETURN: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_LIST_HEDGE_FUND_RETURN_SUCCESS: {
      return {
        ...state,
        HFReturnList: action.data,
        loading: false,
      }
    }
    case GET_LIST_HEDGE_FUND_RETURN_DEFAULT_SUCCESS: {
      return {
        ...state,
        HFReturnDataDefault: action.data,
      }
    }
    case GET_LIST_HEDGE_FUND_RETURN_DATA_ENTRY: {
      return {
        ...state,
        recordDataEntry: null,
        loading: true,
      }
    }
    case GET_LIST_HEDGE_FUND_RETURN_DATA_ENTRY_SUCCESS: {
      return {
        ...state,
        recordDataEntry: action.dataInputReturn,
        loading: false,
      }
    }
    case GET_LIST_HEDGE_FUND_RETURN_ERROR: {
      return {
        ...state,
      }
    }
    case CREATE_INPUT_RETURN: {
      return {
        ...state,
      }
    }
    case GET_LIST_HF_HOLDING_LEVEL: {
      return {
        ...state,
        holdinglevelList: []
      }
    }
    case GET_LIST_HF_HOLDING_LEVEL_SUCCESS: {
      return {
        ...state,
        loading: false,
        holdinglevelList: action.dataHolding
      }
    }
    case GET_VALUE_BASE_DATE_HF_RETURN: {
      return {
        ...state,
        basedate: action.valueBasedate
      }
    }
    default:
      return state
  }
}
export default returnReducer
