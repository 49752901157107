import moment from 'moment'
import {
  CALC_REDEEM_HEDGEFUND_TXN_ERROR,
  CALC_REDEEM_HEDGEFUND_TXN_SUCCESS,
  CREATE_HEDGEFUND_ERROR,
  CREATE_HEDGEFUND_TXN,
  CREATE_HEDGEFUND_TXN_SUCCESS,
  CREATE_HOLDINGS,
  CREATE_HOLDINGS_ERROR,
  CREATE_HOLDINGS_SUCCESS,
  DELETE_HEDGEFUND_TXN_ERROR,
  DELETE_HEDGEFUND_TXN_SUCCESS,
  GET_BY_ID_HEDGEFUND_TXN_ERROR,
  GET_BY_ID_HEDGEFUND_TXN,
  GET_BY_ID_HEDGEFUND_TXN_SUCCESS,
  GET_CLS_FUND_TXN_HEDGEFUND_TXN,
  GET_CLS_FUND_TXN_HEDGEFUND_TXN_SUCCESS,
  GET_CLS_MOUNTING_HEDGEFUND_TXN,
  GET_CLS_MOUNTING_HEDGEFUND_TXN_ERROR,
  GET_CLS_MOUNTING_HEDGEFUND_TXN_SUCCESS,
  GET_LIST_HF_TXN_WITH_BASE_DATE,
  GET_LIST_HF_TXN_WITH_BASE_DATE_DEFAULT_SUCCESS,
  GET_LIST_HF_TXN_WITH_BASE_DATE_ERROR,
  GET_LIST_HF_TXN_WITH_BASE_DATE_SUCCESS,
  GET_REDEEM_MOUNTING_HEDGEFUND_TXN,
  GET_REDEEM_MOUNTING_HEDGEFUND_TXN_ERROR,
  GET_REDEEM_MOUNTING_HEDGEFUND_TXN_SUCCESS,
  GET_VALUE_BASE_DATE_PORT_TS,
  PAGE_NUMBER_PORT_TXN,
  GET_NAV_FINAL_HEDGEFUNDTXN,
  GET_NAV_FINAL_HEDGEFUNDTXN_SUCCESS,
} from '../../constants/ActionTypes'
import { PortTsStateDto } from './stateDtos'

interface ActionProps {
  valueBasedate: string
  type: string
  payload: any
  payloadHoldings: any
  data: any
  recordPortTxn: any
  recordNavFinal: any,
  HedgeFundTxnList: []
  redeemCalcData: []
  HoldingsList: []
  page: number
}
const INIT_STATE: PortTsStateDto = {
  basedate: moment().endOf('month'),
  payload: null,
  payloadHoldings: null,
  recordPortTxn: null,
  HedgeFundTxnList: [],
  mountingData: null,
  clsMountingData: [],
  HedgeFundTxnListDefault: [],
  redeemCalcData: [],
  HoldingsList: [],
  clsFundTxn: null,
  recordNavFinal: [],
  loading: true,
  page: 1,
}
const PortTsReducer = (
  state = INIT_STATE,
  action: ActionProps
): PortTsStateDto => {
  switch (action.type) {
    case PAGE_NUMBER_PORT_TXN: {
      return {
        ...state,
        page: action.page
      }
    }
    case GET_VALUE_BASE_DATE_PORT_TS: {
      return {
        ...state,
        basedate: action.valueBasedate,
      }
    }
    case GET_LIST_HF_TXN_WITH_BASE_DATE: {
      return {
        ...state,
        HedgeFundTxnList: [],
        loading: true,
      }
    }
    case GET_LIST_HF_TXN_WITH_BASE_DATE_SUCCESS: {
      return {
        ...state,
        HedgeFundTxnList: action.data,
        loading: false,
      }
    }
    case GET_LIST_HF_TXN_WITH_BASE_DATE_DEFAULT_SUCCESS: {
      return {
        ...state,
        HedgeFundTxnListDefault: action.data,
      }
    }
    case GET_LIST_HF_TXN_WITH_BASE_DATE_ERROR: {
      return {
        ...state,
        loading: false,
      }
    }
    case CREATE_HEDGEFUND_TXN: {
      return {
        ...state,
      }
    }
    case CREATE_HEDGEFUND_TXN_SUCCESS: {
      return {
        ...state,
      }
    }
    case CREATE_HEDGEFUND_ERROR: {
      return {
        ...state,
      }
    }
    case CREATE_HOLDINGS: {
      return {
        ...state,
      }
    }
    case CREATE_HOLDINGS_SUCCESS: {
      return {
        ...state,
      }
    }
    case CREATE_HOLDINGS_ERROR: {
      return {
        ...state,
      }
    }
    case GET_BY_ID_HEDGEFUND_TXN: {
      return {
        ...state,
        recordPortTxn: null,
      }
    }
    case GET_BY_ID_HEDGEFUND_TXN_SUCCESS: {
      return {
        ...state,
        recordPortTxn: action.payload,
      }
    }
    case GET_CLS_FUND_TXN_HEDGEFUND_TXN: {
      return {
        ...state,
        clsFundTxn: null,
      }
    }
    case GET_CLS_FUND_TXN_HEDGEFUND_TXN_SUCCESS: {
      return {
        ...state,
        clsFundTxn: action.payload,
      }
    }
    case GET_BY_ID_HEDGEFUND_TXN_ERROR: {
      return {
        ...state,
      }
    }
    case GET_REDEEM_MOUNTING_HEDGEFUND_TXN: {
      return {
        ...state,
        mountingData: null,
      }
    }
    case GET_REDEEM_MOUNTING_HEDGEFUND_TXN_SUCCESS: {
      return {
        ...state,
        mountingData: action.data,
      }
    }
    case GET_CLS_MOUNTING_HEDGEFUND_TXN: {
      return {
        ...state,
        clsMountingData: [],
        loading: true,
      }
    }
    case GET_CLS_MOUNTING_HEDGEFUND_TXN_SUCCESS: {
      return {
        ...state,
        clsMountingData: action.data,
        loading: false,
      }
    }
    case GET_NAV_FINAL_HEDGEFUNDTXN: {
      return {
        ...state,
        recordNavFinal: []
      }
    }
    case GET_NAV_FINAL_HEDGEFUNDTXN_SUCCESS: {
      return {
        ...state,
        recordNavFinal: action.payload
      }
    }
    case CALC_REDEEM_HEDGEFUND_TXN_SUCCESS: {
      return {
        ...state,
        redeemCalcData: action.payload,
      }
    }
    case CALC_REDEEM_HEDGEFUND_TXN_ERROR: {
      return {
        ...state,
      }
    }
    case GET_REDEEM_MOUNTING_HEDGEFUND_TXN_ERROR: {
      return {
        ...state,
      }
    }
    case GET_CLS_MOUNTING_HEDGEFUND_TXN_ERROR: {
      return {
        ...state,
      }
    }
    case DELETE_HEDGEFUND_TXN_SUCCESS: {
      return {
        ...state,
      }
    }
    case DELETE_HEDGEFUND_TXN_ERROR: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
export default PortTsReducer
