import {
  GET_LIST_HEDGEFUND,
  GET_LIST_HEDGEFUND_SUCCESS,
  GET_LIST_HEDGEFUND_DEFAULT,
  GET_BY_ID_HEDGEFUND_SUCCESS,
  CREATE_HEDGEFUND_SUCCESS,
  CREATE_HEDGEFUND_ERROR,
  UPDATE_HEDGEFUND_SUCCESS,
  UPDATE_HEDGEFUND_ERROR,
  UPDATE_HEDGEFUND,
  GET_LIST_HEDGEFUND_ACTIVE,
  PAGE_NUMBER_HF,
} from '../../constants/ActionTypes'
import { HedgeFundItem } from '../../dto'
import { HedgeFundStateDto } from './stateDtos'
interface ActionProps {
  id: number
  type: string
  data: HedgeFundItem[]
  error: string
  loading: boolean
  payload: HedgeFundItem
  page: number
  tab: string
}
const INIT_STATE: HedgeFundStateDto = {
  loading: true,
  CreateAlert: true,
  UpdateAlert: true,
  hedgeFundList: [],
  dataDefault: [],
  hedgeFundListActive: [],
  record: null,
  page: 1,
  tab: 'Active',
  id: 0,
}
const HedgeFundReducer = (
  state = INIT_STATE,
  action: ActionProps
): HedgeFundStateDto => {
  switch (action.type) {
    case PAGE_NUMBER_HF: {
      return {
        ...state,
        page: action.page,
        tab: action.tab
      }
    }
    case GET_LIST_HEDGEFUND: {
      return {
        ...state,
        loading: true,
        hedgeFundList: [],
        hedgeFundListActive: []
      }
    }
    case GET_LIST_HEDGEFUND_SUCCESS: {
      return {
        ...state,
        loading: false,
        hedgeFundList: action.data,
      }
    }
    case GET_LIST_HEDGEFUND_DEFAULT: {
      return {
        ...state,
        loading: false,
        dataDefault: action.data,
      }
    }
    case GET_LIST_HEDGEFUND_ACTIVE: {
      const activeList = action.data.filter((x) => x.isActive == true)
      return {
        ...state,
        loading: false,
        hedgeFundListActive: activeList,
      }
    }
    case CREATE_HEDGEFUND_SUCCESS: {
      const newList = [...state.hedgeFundList]
      newList.push(action.payload)
      return {
        ...state,
        hedgeFundList: newList,
        dataDefault: newList,
      }
    }
    case CREATE_HEDGEFUND_ERROR: {
      return {
        ...state,
      }
    }
    case UPDATE_HEDGEFUND: {
      return {
        ...state,
      }
    }
    case UPDATE_HEDGEFUND_SUCCESS: {
      const newList = state.hedgeFundList.map(function (item) {
        return item.id == action.payload.id ? action.payload : item
      })
      return {
        ...state,
        hedgeFundList: newList,
      }
    }
    case UPDATE_HEDGEFUND_ERROR: {
      return {
        ...state,
        UpdateAlert: false,
      }
    }
    case GET_BY_ID_HEDGEFUND_SUCCESS: {
      return {
        ...state,
        record: action.payload,
        id: action.id,
      }
    }
    default:
      return state
  }
}
export default HedgeFundReducer
