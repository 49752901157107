/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SystemUsersApi, AuthApi } from '../../../client-axios/api'
import { Dispatch } from 'redux'
import {
  STATUS_BAD_REQUEST,
  STATUS_CODE_CREATE_SUCCESS,
  STATUS_CODE_SUCCESS,
  STATUS_CODE_UPDATE_SUCCESS,
} from '../../../constants/StatusCode'
import { configHeader } from '../../../core/token'
import { axiosInstance } from '../../../core/axios'
import {
  CREATE_LOGIN_SYSUSER_ERROR,
  CREATE_LOGIN_SYSUSER_SUCCESS,
  CREATE_SYSUSER_ERROR,
  CREATE_SYSUSER_SUCCESS,
  GET_AVATAR_PROFILE_ERROR,
  GET_AVATAR_PROFILE_SUCCESS,
  GET_LIST_SYSUSER_DEFAULT_SUCCESS,
  GET_LIST_SYSUSER_ERROR,
  GET_LIST_SYSUSER_SUCCESS,
  SYSUSER_PROFILE_ERROR,
  SYSUSER_PROFILE_SUCCESS,
  UPDATE_SYSUSER_ERROR,
  UPDATE_SYSUSER_SUCCESS,
} from '../../../constants/ActionTypes'
import { message } from 'antd'
import { showMessage } from '../../../util/showMessage'
import { capitalizeFirstLetter } from '../../../util/function'
export const getListUser = () => (dispatch: Dispatch) => {
  const sysUser = new SystemUsersApi(configHeader(), undefined, axiosInstance)
  return sysUser
    .findAllWithSysusers()
    .then((res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({ type: GET_LIST_SYSUSER_SUCCESS, data: res.data })
        dispatch({ type: GET_LIST_SYSUSER_DEFAULT_SUCCESS, data: res.data })
      }
    })
    .catch((error) => {
      if (error?.response?.status === STATUS_BAD_REQUEST) {
        dispatch({
          type: GET_LIST_SYSUSER_ERROR,
          error: error.response?.data?.detail?.message,
        })
        message.error(error.response?.data?.detail?.message)
        return error?.response?.statusCode
      }
    })
}
export const createSysUser =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const Auth = new AuthApi(configHeader(), undefined, axiosInstance)
    return Auth.registerWithAuth(payload)
      .then((res) => {
        if (res.status === STATUS_CODE_CREATE_SUCCESS) {
          dispatch({ type: CREATE_SYSUSER_SUCCESS, payload: res.data })
        }
        return res?.status
      })
      .catch((error) => {
        if (error?.response?.status === STATUS_BAD_REQUEST) {
          dispatch({
            type: CREATE_SYSUSER_ERROR,
            error: error.response?.data?.detail?.message,
          })
          showMessage('error' ,capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
          return error?.response?.statusCode
        }
      })
  }
export const createLoginUser =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const Auth = new AuthApi(configHeader(), undefined, axiosInstance)
    return Auth.createLoginWithAuth(payload.id, payload.data)
      .then((res) => {
        if (res.status === STATUS_CODE_CREATE_SUCCESS) {
          dispatch({ type: CREATE_LOGIN_SYSUSER_SUCCESS, payload: res.data })
        }
        return res?.status
      })
      .catch((error) => {
        if (error?.response?.status === STATUS_BAD_REQUEST) {
          dispatch({
            type: CREATE_LOGIN_SYSUSER_ERROR,
            error: error.response?.data?.detail?.message,
          })
          showMessage('error' ,capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
          return error?.response?.statusCode
        }
      })
  }
export const updateSysUser =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const sysUser = new SystemUsersApi(configHeader(), undefined, axiosInstance)
    return sysUser
      .updateWithSysusers(payload.id, payload.data)
      .then((res) => {
        if (res.status === STATUS_CODE_UPDATE_SUCCESS) {
          dispatch({ type: UPDATE_SYSUSER_SUCCESS, payload: res.data })
        }
        return res?.status
      })
      .catch((error) => {
        if (error?.response?.status === STATUS_BAD_REQUEST) {
          dispatch({
            type: UPDATE_SYSUSER_ERROR,
            error: error.response?.data?.detail?.message,
          })
          showMessage('error' ,capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
          return error?.response?.statusCode
        }
      })
  }
export const resendPassword =
  (payload: any): unknown =>
  () => {
    const Auth = new AuthApi(configHeader(), undefined, axiosInstance)
    return Auth.resendWithAuth(payload)
      .then((res) => {
        return res.status
      })
      .catch((error) => {
        if (
          (error.response?.data?.detail?.message).includes(
            'FORCE_CHANGE_PASSWORD'
          )
        ) {
          showMessage('error' , 'The account has changed its initial password!')
        } else {
          showMessage('error' ,capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
        }
        return error?.response?.statusCode
      })
  }
export const getProfileUser =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const sysUser = new SystemUsersApi(configHeader(), undefined, axiosInstance)
    return sysUser
      .findNameWithSysusers(payload.sub)
      .then((res) => {
        if (res.status === STATUS_CODE_UPDATE_SUCCESS) {
          dispatch({ type: SYSUSER_PROFILE_SUCCESS, payload: res.data })
        }
        return res?.status
      })
      .catch((error) => {
        if (error?.response?.status === STATUS_BAD_REQUEST) {
          dispatch({
            type: SYSUSER_PROFILE_ERROR,
            error: error.response?.data?.detail?.message,
          })
          message.error(error.response?.data?.detail?.message)
          return error?.response?.statusCode
        }
      })
    }
    export const getAvatarUser =
    (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const sysUser = new SystemUsersApi(configHeader(), undefined, axiosInstance)
      return sysUser
        .findOneAvatarWithSysusers(payload.value)
        .then((res) => {
          if (res.status === STATUS_CODE_UPDATE_SUCCESS) {
            dispatch({ type: GET_AVATAR_PROFILE_SUCCESS, avatar: res.data })
          }
          return res?.status
        })
        .catch((error) => {
          if (error?.response?.status === STATUS_BAD_REQUEST) {
            dispatch({
              type: GET_AVATAR_PROFILE_ERROR,
            })
            message.error(error.response?.data?.detail?.message)
            return error?.response?.statusCode
          }
        })
    }
