import { GET_NAVDATA } from "../../constants/ActionTypes";
import { DataItemNAVStateDto} from './stateDtos';
interface ActionProps {
    type: string,
    payload: ""
}
const INIT_STATE : DataItemNAVStateDto= {
    dataNAVList :[{
        id:1,
        jpy_class:{
            year: 2020,
            jan:"8.094",
            feb:"8.094",
            mar:"8.094",
            apr:"8.094",
            may:"8.094",
            jun:"8.094",
            jul:"8.094",
            aug:"8.094",
            sep:"8.094",
            oct:"8.094",
            nov:"8.094",
            dec:"8.094",
            ytd:"8.094",
        },
    },{
        id:2,
        jpy_class:{
            year: 2019,
            jan:"5.123",
            feb:"5.123",
            mar:"5.123",
            apr:"5.123",
            may:"5.123",
            jun:"5.123",
            jul:"5.123",
            aug:"5.123",
            sep:"5.123",
            oct:"5.123",
            nov:"5.123",
            dec:"5.123",
            ytd:"5.123",
        },
    },{
        id:3,
        jpy_class:{
            year: 2018,
            jan:".....",
            feb:".....",
            mar:".....",
            apr:".....",
            may:".....",
            jun:".....",
            jul:".....",
            aug:".....",
            sep:".....",
            oct:".....",
            nov:".....",
            dec:".....",
            ytd:".....",
        },
    }]
}
const dataNAVReducer = (state = INIT_STATE, action: ActionProps ):DataItemNAVStateDto => {
    switch (action.type) {
        case GET_NAVDATA:
          {
            return {
              ...state
            }
          }
        default:
          return state;
      }
}
export default dataNAVReducer