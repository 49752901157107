import { message } from "antd"

export const showMessage = (type: any, messageText: string) => {
    const key = "custom-message";
    message.open({
        type: type,
        content: (
            messageText
        ),
        duration: 0,
        onClick: () => message.destroy(key),
        key,
    });
}