/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import Amplify, { Auth } from 'aws-amplify'
import { Button, Form, Input, message } from 'antd'
import { injectIntl } from 'react-intl'
import IntlTranslate from '../util/IntlTranslate'
import IntlMessages from '../util/IntlMessages'
import AmplifyConfig from '../util/aws-exports'
import { useDispatch } from 'react-redux'
import { congitoUser, userCompleteNewPassword } from '../appRedux/actions'
import { useHistory } from 'react-router-dom'
const FormItem = Form.Item
Amplify.configure(AmplifyConfig)
const ResetPassword = (props: { intl: any }) => {
  const { intl } = props
  const t = IntlTranslate(intl)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory();

  useEffect(() => {
    congitoUser.subscribe((value: any) => {
      if(!value) history.push('/signin');
    })
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const email = params.get('email');
    form.setFieldsValue({ user: email });
  })


  const onFinish = async () => {
    const values = await form.validateFields()
    dispatch(userCompleteNewPassword(values))
  }

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header">
          <img
            src="/assets/images/logo-white.png"
            alt="wieldy"
            title="wieldy"
          />
        </div>
        <div className="gx-mb-4">
          <h2>{t('profile.title.resetPassword')}</h2>
          <p>
            <IntlMessages id="appModule.enterPasswordReset" />
          </p>
        </div>

        <Form
          initialValues={{ remember: true }}
          form={form}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            rules={[
              { required: true, message: t('profile.message.input.registration.email') },
            ]}
            name="user"
          >
            <Input
              className="gx-input-lineheight"
              placeholder={t('profile.email')}
            />
          </FormItem>

          <FormItem
            rules={[
              {
                required: true,
                message: t('profile.message.input.new.password'),
              },
            ]}
            name="password"
          >
            <Input
              className="gx-input-lineheight"
              placeholder={t('profile.new.password')}
              type="password"
            />
          </FormItem>

          <FormItem>
            <Button type="primary" className="gx-mb-0" onClick={onFinish}>
              <IntlMessages id="btn.save" />
            </Button>
            {/* <span>
              <IntlMessages id="app.userAuth.or" />
            </span>{' '}
            <Link to="/signin">
              <IntlMessages id="app.userAuth.signIn" />
            </Link> */}
          </FormItem>
        </Form>
      </div>
    </div>
  )
}

export default injectIntl(ResetPassword)
