import {
  GET_BY_ID_SERIES,
  //   GET_BY_ID_SERIES_ERROR,
  GET_BY_ID_SERIES_SUCCESS,
  GET_LIST_ACCOUNT_SERIES,
  GET_LIST_ACCOUNT_SERIES_SUCCESS,
  GET_LIST_ACCOUNT_SERIES_ERROR,
  CREATE_SERIES_SUCCESS,
  CREATE_SERIES_ERROR,
  UPDATE_SERIES_SUCCESS,
  UPDATE_SERIES_ERROR,
  DELETE_SERIES_SUCCESS,
  GET_LIST_SERIES_BY_ACCT_CLASSES,
  GET_LIST_SERIES_BY_ACCT_CLASSES_SUCCESS,
  DELETE_SERIES_ERROR,
  DELETE_SERIES,
} from '../../constants/ActionTypes'
import { SeriesStateDto } from './stateDtos'
import { SeriesItem } from '../../dto'
interface ActionProps {
  id: number
  type: string
  payload: SeriesItem
  data: SeriesItem[]
}
const INIT_STATE: SeriesStateDto = {
  id: 0,
  seriesList: [],
  CreateAlert: true,
  UpdateAlert: true,
  DeleteAlert: true,
  recordSeries: null,
  record: null,
}
const SeriesReducer = (state = INIT_STATE, action: ActionProps): unknown => {
  switch (action.type) {
    case GET_LIST_ACCOUNT_SERIES: {
      return {
        ...state,
      }
    }
    case GET_LIST_ACCOUNT_SERIES_SUCCESS: {
      return {
        ...state,
        seriesList: action.data,
      }
    }
    case GET_LIST_ACCOUNT_SERIES_ERROR: {
      return {
        ...state,
      }
    }
    case GET_LIST_SERIES_BY_ACCT_CLASSES: {
      return {
        ...state,
      }
    }
    case GET_LIST_SERIES_BY_ACCT_CLASSES_SUCCESS: {
      return {
        ...state,
        seriesList: action.data,
      }
    }
    case CREATE_SERIES_SUCCESS: {
      const newList = [...state.seriesList]
      newList.push(action.payload)
      return {
        ...state,
        seriesList: newList,
        CreateAlert: true,
      }
    }
    case CREATE_SERIES_ERROR: {
      return {
        ...state,
        recordSeries: null,
      }
    }
    case UPDATE_SERIES_SUCCESS: {
      const newList = state.seriesList.map(function (item) {
        return item.id == action.payload.id ? action.payload : item
      })
      return {
        ...state,
        seriesList: newList,
      }
    }
    case UPDATE_SERIES_ERROR: {
      return {
        ...state,
        UpdateAlert: true,
      }
    }
    case DELETE_SERIES: {
      return {
        ...state,
      }
    }
    case DELETE_SERIES_SUCCESS: {
      const newList = state.seriesList.filter(
        (item) => item.id !== action.payload
      )
      return {
        ...state,
        seriesList: newList,
        DeleteAlert: true,
      }
    }
    case DELETE_SERIES_ERROR: {
      return {
        ...state,
        DeleteAlert: false,
      }
    }
    case GET_BY_ID_SERIES: {
      return {
        ...state,
        record: null,
      }
    }
    case GET_BY_ID_SERIES_SUCCESS: {
      return {
        ...state,
        recordSeries: action.payload,
        id: action.id,
      }
    }
    default:
      return state
  }
}

export default SeriesReducer
