import { AllocationsApi } from '../../../client-axios/api'
import {
  STATUS_CODE_SUCCESS,
} from '../../../constants/StatusCode'
import { configHeader } from '../../../core/token'
import { axiosInstance } from '../../../core/axios'
import { message } from 'antd'
export const getListAllocation = (payload: ({portfolioId:any, baseDate: string})):unknown => {
  const allocationApi = new AllocationsApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  return async () => {
    try {
      return allocationApi
        .findAllUnderPortfolioByBaseDateWithAllocations(
          payload.portfolioId,
          payload.baseDate
        )
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            return res.data
          } else {
            return res
          }
        })
    } catch (error) {
      message.error((error as any)?.message);
    }
  }
}
