import React from 'react'
import { injectIntl } from 'react-intl'
import { Card } from 'antd'
const DashBoards = () => {
  return (
    <div className="content-wrapper">
      <Card>
        <h1 style={{ textAlign: 'center' }}>Welcome to Quark</h1>
      </Card>
      {/* <Breadcrumb className="breadcrumb-header margin-bottom-25">
        <Breadcrumb.Item>Data Team DashBoards</Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <Form>
          <Row>
            <Col lg={14} md={14} sm={14} xs={24}>
              <Card title="NAV/TXN Registration Progress">
                <Row>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <Form.Item label="Account">
                        <Select></Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={6} xs={24}>
                    <div className="gx-form-row0">
                      <Form.Item>
                        <Button type="primary">Run</Button>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col>
                    <Table
                      bordered
                      size="small"
                      className="components-table-custom"
                      columns={RegistrationTable}
                      dataSource={fakeData}
                      pagination={false}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={10} md={10} sm={10} xs={24}>
              <Card title="Manager Estimate Progress">
                <Table
                  dataSource={fakeData2}
                  pagination={false}
                  columns={ManagerTable}
                  size="small"
                  className="components-table-custom"
                />
              </Card>
            </Col>
          </Row>
        </Form>
      </div> */}
    </div>
  )
}
export default injectIntl(DashBoards)
