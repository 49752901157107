import { DataViewInvestorStateDto } from '../../reducers/stateDtos'
import { GET_LIST_DATA_VIEW_INVESTOR, GET_LIST_DATA_VIEW_INVESTOR_ACTIVE, GET_LIST_DATA_VIEW_INVESTOR_DEFAULT, GET_LIST_DATA_VIEW_INVESTOR_SUCCESS } from '../../../constants/ActionTypes'

interface ActionProps {
    data: any[]
    type: string
}
const INIT_STATE: DataViewInvestorStateDto = {
    dataViewInvList: [],
    dataViewInvListDefault: [],
    dataViewInvActive: [],
    loading: true,
}
const DataViewInvestorReducer = (
    state = INIT_STATE,
    action: ActionProps
): any => {
    switch (action.type) {
        case GET_LIST_DATA_VIEW_INVESTOR: {
            return {
                ...state,
                dataViewInvList: [],
                dataViewInvActive: []
            }
        }
        case GET_LIST_DATA_VIEW_INVESTOR_SUCCESS: {
            return {
                ...state,
                dataViewInvList: action.data,
            }
        }
        case GET_LIST_DATA_VIEW_INVESTOR_DEFAULT: {
            return {
                ...state,
                dataViewInvListDefault: action.data,
            }
        }
        case GET_LIST_DATA_VIEW_INVESTOR_ACTIVE: {
            const activeList = action.data.filter((x) => x.isActive == true)
            return {
                ...state,
                dataViewInvActive: activeList,
            }
        }
        default:
            return state
    }
}
export default DataViewInvestorReducer