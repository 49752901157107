import {
  GET_LIST_STRATEGY,
  GET_LIST_STRATEGY_DEFAULT,
  GET_LIST_STRATEGY_SUCCESS,
} from '../../constants/ActionTypes'
import { StrategyItem } from '../../dto'

interface ActionProps {
  data: StrategyItem[]
  type: string
}
const INIT_STATE = {
  data: [],
}
const StrategyReducer = (state = INIT_STATE, action: ActionProps): any => {
  switch (action.type) {
    case GET_LIST_STRATEGY: {
      return {
        ...state,
      }
    }
    case GET_LIST_STRATEGY_SUCCESS: {
      return {
        ...state,
        strategyList: action.data,
      }
    }
    case GET_LIST_STRATEGY_DEFAULT: {
      return {
        ...state,
        strategyListDefault: action.data,
      }
    }
    default:
      return state
  }
}
export default StrategyReducer
