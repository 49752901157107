import { any } from 'prop-types'
import {
  CREATE_AC_DIVIDEND_TRANSACTION_ERROR,
  CREATE_AC_DIVIDEND_TRANSACTION_SUCCESS,
  CREATE_AC_INVESTOR_TRANSACTION,
  CREATE_AC_INVESTOR_TRANSACTION_ERROR,
  CREATE_AC_INVESTOR_TRANSACTION_SUCCESS,
  CREATE_AC_REDEEM_TRANSACTION,
  CREATE_AC_REDEEM_TRANSACTION_ERROR,
  CREATE_AC_REDEEM_TRANSACTION_SUCCESS,
  DELETE_AC_DIVIDEND_TRANSACTION_ERROR,
  DELETE_AC_DIVIDEND_TRANSACTION_SUCCESS,
  DELETE_AC_INVESTOR_TRANSACTION,
  DELETE_AC_INVESTOR_TRANSACTION_ERROR,
  DELETE_AC_INVESTOR_TRANSACTION_SUCCESS,
  GET_ACCOUNT_TS_FUND_TXN,
  GET_ACCOUNT_TS_FUND_TXN_ERROR,
  GET_ACCOUNT_TS_FUND_TXN_SUCCESS,
  GET_BY_ID_AC_DIVIDEND_TRANSACTION_ERROR,
  GET_BY_ID_AC_DIVIDEND_TRANSACTION_SUCCESS,
  GET_INVESTOR_WITH_TRANSACTION_ID,
  GET_INVESTOR_WITH_TRANSACTION_ID_ERROR,
  GET_INVESTOR_WITH_TRANSACTION_ID_SUCCESS,
  GET_LIST_INVESTOR_TRANSACTION,
  GET_LIST_INVESTOR_TRANSACTION_WITH_ID,
  GET_LIST_INVESTOR_TRANSACTION_WITH_ID_DEFAULT,
  GET_LIST_INVESTOR_TRANSACTION_WITH_ID_ERROR,
  GET_VALUE_ACCOUNT_INVESTOR,
  GET_VALUE_BASE_DATE_INVESTOR,
  UPDATE_AC_DIVIDEND_TRANSACTION_ERROR,
  UPDATE_AC_DIVIDEND_TRANSACTION_SUCCESS,
  UPDATE_AC_INVESTOR_TRANSACTION,
  UPDATE_AC_INVESTOR_TRANSACTION_ERROR,
  UPDATE_AC_INVESTOR_TRANSACTION_SUCCESS,
  GET_NAV_FINAL_INVESTTXN,
  GET_NAV_FINAL_INVESTTXN_SUCCESS
} from '../../constants/ActionTypes'
import { InvTXNStateDto } from './stateDtos'
interface ActionProps {
  type: string
  payload: any
  recordInvs: InvTXNStateDto
  recordInvsList: any,
  recordInvsListDefault: any
  recordDividend: any,
  recordNavFinal: any,
  value: number,
  valueBasedate: string,
  accountTsFundTxn: any,
  InvsTransactionList: []
}
const INIT_STATE: any = {
  recordInvs: null,
  recordInvsList: null,
  recordInvsListDefault: null,
  payload: any,
  recordDividend: null,
  accountTsFundTxn: null,
  recordNavFinal: null,
  value: null,
  InvsTransactionList: [],
}
const InvestorTransactionReducer = (
  state = INIT_STATE,
  action: ActionProps
): InvTXNStateDto => {
  switch (action.type) {
    case GET_LIST_INVESTOR_TRANSACTION: {
      return {
        ...state,
        recordInvsList: null,
      }
    }
    case GET_LIST_INVESTOR_TRANSACTION_WITH_ID: {
      return {
        ...state,
        recordInvsList: action.payload,
      }
    }
    case GET_LIST_INVESTOR_TRANSACTION_WITH_ID_DEFAULT: {
      return {
        ...state,
        recordInvsListDefault: action.payload,
      }
    }
    case GET_LIST_INVESTOR_TRANSACTION_WITH_ID_ERROR: {
      return {
        ...state,
      }
    }
    case GET_INVESTOR_WITH_TRANSACTION_ID: {
      return {
        ...state,
        recordInvs: null,
      }
    }
    case GET_INVESTOR_WITH_TRANSACTION_ID_SUCCESS: {
      return {
        ...state,
        recordInvs: action.payload,
      }
    }
    case GET_INVESTOR_WITH_TRANSACTION_ID_ERROR: {
      return {
        ...state,
      }
    }
    case CREATE_AC_INVESTOR_TRANSACTION: {
      return {
        ...state,
      }
    }
    case CREATE_AC_INVESTOR_TRANSACTION_SUCCESS: {
      return {
        ...state,
      }
    }
    case CREATE_AC_INVESTOR_TRANSACTION_ERROR: {
      return {
        ...state,
      }
    }
    case UPDATE_AC_INVESTOR_TRANSACTION: {
      return {
        ...state,
      }
    }
    case UPDATE_AC_INVESTOR_TRANSACTION_SUCCESS: {
      // const newList = state.InvsTransactionList.map(function (item: {
      //   id: any
      // }) {
      //   return item.id == action.payload.id ? action.payload : item
      // })

      return {
        ...state,
      }
    }
    case UPDATE_AC_INVESTOR_TRANSACTION_ERROR: {
      return {
        ...state,
      }
    }
    case DELETE_AC_INVESTOR_TRANSACTION: {
      return {
        ...state,
      }
    }
    case DELETE_AC_INVESTOR_TRANSACTION_SUCCESS: {
      // const newList = state.InvsTransactionList.filter(
      //   (item: { id: any }) => item.id !== action.payload
      // )
      return {
        ...state,
        // InvsTransactionList: newList,
      }
    }
    case DELETE_AC_INVESTOR_TRANSACTION_ERROR: {
      return {
        ...state,
      }
    }
    case GET_VALUE_ACCOUNT_INVESTOR: {
      return {
        ...state,
        accountId: action.value
      }
    }
    case GET_VALUE_BASE_DATE_INVESTOR: {
      return {
        ...state,
        basedate: action.valueBasedate
      }
    }
    case GET_NAV_FINAL_INVESTTXN: {
      return {
        ...state,
        recordNavFinal: null
      }
    }
    case GET_NAV_FINAL_INVESTTXN_SUCCESS: {
      return {
        ...state,
        recordNavFinal: action.payload
      }
    }
    case CREATE_AC_REDEEM_TRANSACTION: {
      return {
        ...state
      }
    }
    case CREATE_AC_REDEEM_TRANSACTION_SUCCESS: {
      return {
        ...state,
      }
    }
    case CREATE_AC_REDEEM_TRANSACTION_ERROR: {
      return {
        ...state
      }
    }
    case CREATE_AC_DIVIDEND_TRANSACTION_SUCCESS: {
      const newList = [...state.InvsTransactionList]
      newList.push(action.payload)
      return {
        ...state,
        InvsTransactionList: newList,
      }
    }
    case CREATE_AC_DIVIDEND_TRANSACTION_ERROR: {
      return {
        ...state
      }
    }
    case UPDATE_AC_DIVIDEND_TRANSACTION_SUCCESS: {
      return {
        ...state,
        InvsTransactionList: action.payload,
      }
    }
    case UPDATE_AC_DIVIDEND_TRANSACTION_ERROR: {
      return {
        ...state
      }
    }
    case DELETE_AC_DIVIDEND_TRANSACTION_SUCCESS: {
      const newList = state.InvsTransactionList.filter(
        (item: { id: any }) => item.id !== action.payload
      )
      return {
        ...state,
        InvsTransactionList: newList,
      }
    }
    case DELETE_AC_DIVIDEND_TRANSACTION_ERROR: {
      return {
        ...state,
      }
    }
    case GET_ACCOUNT_TS_FUND_TXN: {
      return {
        ...state,
        accountTsFundTxn: null
      }
    }
    case GET_ACCOUNT_TS_FUND_TXN_SUCCESS: {
      return {
        ...state,
        accountTsFundTxn: action.payload
      }
    }
    case GET_ACCOUNT_TS_FUND_TXN_ERROR: {
      return {
        ...state,
        accountTsFundTxn: null
      }
    }
    case GET_BY_ID_AC_DIVIDEND_TRANSACTION_SUCCESS: {
      return {
        ...state,
        recordDividend: action.payload
      }
    }
    case GET_BY_ID_AC_DIVIDEND_TRANSACTION_ERROR: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
export default InvestorTransactionReducer
