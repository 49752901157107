import { GET_LIST_ACCOUNT } from "../../constants/ActionTypes";
import { HFIndexDataDto } from './stateDtos';
interface ActionProps {
    type: string,
    payload: HFIndexDataDto
}

const INIT_STATE: HFIndexDataDto = {
    hf_index_data: [
        {
            id: 1,
            name: "SCML Fund",
            abbreviation: "USD",
            ccy: "YYYY",
            strategy: "Other",
            type: "Alpha A",
            region: "..."
        },
        {
            id: 2,
            name: "HFR CB",
            abbreviation: "USD",
            ccy: "YYYY",
            strategy: "Credit",
            type: "Alpha B",
            region: "..."
        },
    ],
};
const HFIndexReducer = (state = INIT_STATE, action: ActionProps): HFIndexDataDto => {
    switch (action.type) {
        case GET_LIST_ACCOUNT: {
            return {
                ...state,
            };
        }
        default:
            return state;
    }
};
export default HFIndexReducer;
