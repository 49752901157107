/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'
import {
  GET_LIST_ACCOUNT,
  GET_LIST_ACCOUNT_SUCCESS,
  GET_LIST_ACCOUNT_ERROR,
  GET_BY_ID_ACCOUNT,
  GET_BY_ID_ACCOUNT_SUCCESS,
  GET_BY_ID_ACCOUNT_ERROR,
  GET_ALL_CLASS_BY_ACCOUNT_ID,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  GET_LIST_ACTIVE_ACCOUNTS_SUCCESS,
  GET_LIST_ACCOUNT_DEFAULT_SUCCESS,
  GET_LIST_ACTIVE_ACCOUNTS_MASTER_SUCCESS,
} from '../../../constants/ActionTypes'
// import { AccountItem } from '../../dto'
import {
  STATUS_BAD_REQUEST,
  STATUS_CODE_SUCCESS,
  STATUS_CODE_UPDATE_SUCCESS,
  // STATUS_CODE_CREATE_SUCCESS,
} from '../../../constants/StatusCode'
import { AccountsApi, HedgefundTransactionsApi } from '../../../client-axios/api'
import { axiosInstance } from '../../../core/axios'
import { configHeader } from '../../../core/token'
import { message } from 'antd'
import { capitalizeFirstLetter } from '../../../util/function'
import { showMessage } from '../../../util/showMessage'
export const getListAccount = () => (dispatch: Dispatch) => {
  const accountsApi = new AccountsApi(configHeader(), undefined, axiosInstance)
  dispatch({ type: GET_LIST_ACCOUNT })
  return accountsApi
    .findAllWithAccounts()
    .then((res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({ type: GET_LIST_ACCOUNT_SUCCESS, data: res.data })
        dispatch({ type: GET_LIST_ACCOUNT_DEFAULT_SUCCESS, data: res.data })
        dispatch({ type: GET_LIST_ACTIVE_ACCOUNTS_MASTER_SUCCESS, data: res.data })
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_LIST_ACCOUNT_ERROR,
        error: error.response?.data?.detail?.message,
      })
    })
}
export const createAccount =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      dispatch({ type: CREATE_ACCOUNT })
      const accountsApi = new AccountsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return accountsApi
        .createWithAccounts(payload)
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({ type: CREATE_ACCOUNT_SUCCESS, data: res.data })
          }
          return res?.status
        })
        .catch((error) => {
          if (error?.response?.status === STATUS_BAD_REQUEST) {
            dispatch({
              type: CREATE_ACCOUNT_ERROR,
              Error: error?.response?.data?.detail?.message,
            })
            showMessage('error', capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
            return error?.response?.statusCode
          }
        })
    }
export const updateAccount =
  (payload: { id: any; data: any }): unknown =>
    (dispatch: Dispatch) => {
      const accountsApi = new AccountsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      dispatch({ type: UPDATE_ACCOUNT })
      return accountsApi
        .updateWithAccounts(payload.id, payload.data)
        .then((res) => {
          if (res?.status === STATUS_CODE_UPDATE_SUCCESS) {
            dispatch({ type: UPDATE_ACCOUNT_SUCCESS, payload: res.data })
          }
          return res?.status
        })
        .catch(async (error) => {
          if (error.response) {
            dispatch({
              type: UPDATE_ACCOUNT_ERROR,
              error: error.response?.data?.detail?.message,
            })
            showMessage('error', capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
          }
          return error?.response?.statusCode
        })
    }
export const getAccountById =
  (payload: { id: any }): unknown =>
    (dispatch: Dispatch) => {
      const accountsApi = new AccountsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      dispatch({ type: GET_BY_ID_ACCOUNT })

      return accountsApi
        .findOneWithAccounts(payload.id)
        .then((res) => {
          dispatch({ type: GET_BY_ID_ACCOUNT_SUCCESS, payload: res.data })
        })
        .catch((error) => {
          if (error.response) {
            dispatch({
              type: GET_BY_ID_ACCOUNT_ERROR,
              error: error.response?.data?.detail?.message,
            })
          }
        })
    }
export const getClassByFundId =
  (payload: any): unknown =>
    (dispatch: Dispatch) => {
      const accountsApi = new AccountsApi(
        configHeader(),
        undefined,
        axiosInstance
      )
      return accountsApi
        .findClassesByAccountWithAccounts(payload.id, payload.basedate)
        .then((res) => {
          if (res?.status === STATUS_CODE_SUCCESS) {
            dispatch({ type: GET_ALL_CLASS_BY_ACCOUNT_ID, data: res.data })
          }
          return res?.status
        })
        .catch((error) => {
          if (error.response) {
            return error.response
          }
        })
    }
export const getListActiveAccounts = (payload: any) => (dispatch: Dispatch) => {
  const accountsApi = new AccountsApi(configHeader(), undefined, axiosInstance)
  return accountsApi
    .listActiveAccountsWithAccounts(payload.withPortfolio)
    .then((res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({ type: GET_LIST_ACTIVE_ACCOUNTS_SUCCESS, data: res.data })
      }
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      }
    })
}
export const getListAccountsInvestInHF =
  (payload: any) => (dispatch: Dispatch) => {
    const hedgefundTxnApi = new HedgefundTransactionsApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return hedgefundTxnApi
      .getAllAccountInvestInHedgeFundWithHedgefundTransactions(
        payload.hedgeFundId
      )
      .then((res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: GET_LIST_ACTIVE_ACCOUNTS_SUCCESS, data: res.data })
        }
      })
      .catch((error) => {
        if (error.response) {
          return error.response
        }
      })
  }
