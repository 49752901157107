import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'

interface iProps {
  match: {
    url: string
  }
}
const Account = ({ match }: iProps): JSX.Element => (
  <Switch>
    {''}
    <Route
      exact
      path={`${match.url}/doubleCheck`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_AccountNAVDoubleCheck/ListNAVDoubleCheck'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/InvestorTransactionView'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/invest/:basedate/add/:fundId/:fundClassId/:fundSeriesId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeInvest'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/invest/:basedate/:investorId/:divisionId/add/:fundId/:fundClassId/:fundSeriesId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeInvest'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/invest/add/:fundId/:id`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeInvest'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/invest/:basedate/:fundId/:fundClassId/:fundSeriesId/edit/:fundTransactionId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeInvest'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/invest/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeInvest'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/redeem/:basedate/:investorId/:divisionId/:fundTransactionId/add/:fundId/:fundClassId/:fundSeriesId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeRedeem'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/redeem/:basedate/:fundId/:fundClassId/:fundSeriesId/:fundTransactionId/edit/:fundTransactionItemId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeRedeem'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/redeem/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionItemId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeRedeem'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/dividend/:basedate/add/:fundId/:fundClassId/:fundSeriesId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeDividend'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/dividend/:basedate/:fundId/:fundClassId/:fundSeriesId/edit/:dividendId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeDividend'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/dividend/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:dividendId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeDividend'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/eqCrystallization/:basedate/:investorId/:divisionId/add/:fundId/:fundClassId/:fundSeriesId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeEQCrystallization'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/eqCrystallization/:basedate/:fundId/:fundClassId/:fundSeriesId/edit/:fundTransactionId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeEQCrystallization'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/eqCrystallization/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeEQCrystallization'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/other/:basedate/:investorId/:divisionId/add/:fundId/:fundClassId/:fundSeriesId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeOther'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/other/:basedate/:fundId/:fundClassId/:fundSeriesId/edit/:fundTransactionId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeOther'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/other/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeOther'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/adjust/:basedate/:investorId/:divisionId/add/:fundId/:fundClassId/:fundSeriesId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateAdjustPerfomance'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/adjust/:basedate/:fundId/:fundClassId/:fundSeriesId/edit/:fundTransactionId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateAdjustPerfomance'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/adjust/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateAdjustPerfomance'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/other/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateTypeOther'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/extraDividend/:basedate/:investorId/:divisionId/add/:fundId/:fundClassId/:fundSeriesId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateExtraDividend'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/extraDividend/:basedate/:fundId/:fundClassId/:fundSeriesId/edit/:fundTransactionId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateExtraDividend'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/txn/extraDividend/:basedate/:fundId/:fundClassId/:fundSeriesId/detail/:fundTransactionId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/AC_Account/AC_InvestorTransaction/CreateAndUpdateExtraDividend'
          )
      )}
    ></Route>
    <Route
      exact
      path={`${match.url}/nav`}
      component={asyncComponent(
        () => import('../../components/AC_Account/AC_AccountNAV/ListAccountNAV')
      )}
    />
    <Route
      exact
      path={`${match.url}/dataDuplication`}
      component={asyncComponent(
        () =>
          import('../../components/AC_Account/DataDuplicate/dataDuplication')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/nav/:accountId`}
      component={asyncComponent(
        () => import('../../components/AC_Account/AC_AccountNAV/ListAccountNAV')
      )}
    />
  </Switch>
)
export default Account
