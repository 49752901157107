/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux'
import { OrganizationsApi } from '../../../client-axios/api'
import { message } from 'antd'
import { axiosInstance } from '../../../core/axios'
import { configHeader } from '../../../core/token'
import {
  GET_LIST_ORGANIZATION_ERROR,
  GET_LIST_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_ERROR,
  GET_BY_ID_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_ERROR,
  GET_LIST_ORGANIZATION_DEFAULT_SUCCESS,
  GET_LIST_ORGANIZATION,
  UPDATE_ORGANIZATION,
} from '../../../constants/ActionTypes'
import {
  STATUS_CODE_SUCCESS,
  STATUS_CODE_CREATE_SUCCESS,
  STATUS_BAD_REQUEST,
} from '../../../constants/StatusCode'
import { capitalizeFirstLetter } from '../../../util/function'
import { showMessage } from '../../../util/showMessage'
export const getListOrganization = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_LIST_ORGANIZATION })
  const organizationsApi = new OrganizationsApi(
    configHeader(),
    undefined,
    axiosInstance
  )
  organizationsApi
    .findAllWithOrganizations()
    .then((res) => {
      if (res?.status === STATUS_CODE_SUCCESS) {
        dispatch({ type: GET_LIST_ORGANIZATION_SUCCESS, data: res.data })
        dispatch({
          type: GET_LIST_ORGANIZATION_DEFAULT_SUCCESS,
          data: res.data,
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_LIST_ORGANIZATION_ERROR,
        error: error?.response?.data?.message,
      })
      message.error(error?.response?.data?.message)
    })
}
export const createOrganization =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const organizationsApi = new OrganizationsApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    return organizationsApi
      .createWithOrganizations(payload)
      .then((res) => {
        if (res.status === STATUS_CODE_CREATE_SUCCESS) {
          dispatch({ type: CREATE_ORGANIZATION_SUCCESS, data: res.data })
          return res?.status
        }
      })
      .catch((error) => {
        if (error?.response?.status === STATUS_BAD_REQUEST) {
          return dispatch({
            type: CREATE_ORGANIZATION_ERROR,
            Error: error.response?.data?.detail?.message,
          })
        }
        showMessage('error' ,capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
        return error?.response?.statusCode
      })
  }
export const updateOrganization =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const organizationsApi = new OrganizationsApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    dispatch({ type: UPDATE_ORGANIZATION })
    return organizationsApi
      .updateWithOrganizations(payload.id, payload.data)
      .then((res) => {
        if (res.status === STATUS_CODE_SUCCESS) {
          dispatch({
            type: UPDATE_ORGANIZATION_SUCCESS,
            payload: res.data,
          })
          return res?.status
        }
      })
      .catch((error) => {
        if (error.response) {
           dispatch({
            type: UPDATE_ORGANIZATION_ERROR,
            error: error.response?.data?.detail?.message,
          })
          showMessage('error' ,capitalizeFirstLetter(error.response?.data?.detail?.message.replace(/_/g, ' ')))
          return error?.response?.statusCode
        }
      })
  }
export const getOrganizationById =
  (payload: any): unknown =>
  (dispatch: Dispatch) => {
    const organizationsApi = new OrganizationsApi(
      configHeader(),
      undefined,
      axiosInstance
    )
    organizationsApi.findOneWithOrganizations(payload.id).then(
      (res) => {
        if (res?.status === STATUS_CODE_SUCCESS) {
          dispatch({ type: GET_BY_ID_ORGANIZATION_SUCCESS, payload: res.data })
        }
      },
      (error) => {
        dispatch({ type: GET_LIST_ORGANIZATION_ERROR, error: error.response })
      }
    )
  }
