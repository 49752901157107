import {
  CREATE_SYSUSER,
  CREATE_SYSUSER_ERROR,
  CREATE_SYSUSER_SUCCESS,
  CREATE_LOGIN_SYSUSER_ERROR,
  CREATE_LOGIN_SYSUSER_SUCCESS,
  GET_LIST_SYSUSER,
  GET_LIST_SYSUSER_DEFAULT_SUCCESS,
  GET_LIST_SYSUSER_ERROR,
  GET_LIST_SYSUSER_SUCCESS,
  SYSUSER_PROFILE_SUCCESS,
  UPDATE_SYSUSER_ERROR,
  UPDATE_SYSUSER_SUCCESS,
  GET_AVATAR_PROFILE_SUCCESS,
} from '../../constants/ActionTypes'
import { SysUserItem } from '../../dto'
import { SysUserStateDto } from './stateDtos'
interface ActionProps {
  id: number
  type: string
  data: SysUserItem[]
  payload: SysUserItem
  avatar: any,
}
const INIT_STATE: SysUserStateDto = {
  SysUserList: [],
  SysUserDefaultList: [],
  SysUserRecord: null,
  avatar: '',
}
const SysuserReducer = (state = INIT_STATE, action: ActionProps): unknown => {
  switch (action.type) {
    case CREATE_SYSUSER: {
      return {
        ...state,
      }
    }
    case CREATE_SYSUSER_SUCCESS: {
      const newList = [...state.SysUserList]
      newList.push(action.payload)
      return {
        ...state,
        SysUserList: newList,
      }
    }
    case CREATE_SYSUSER_ERROR: {
      return {
        ...state,
      }
    }
    case CREATE_LOGIN_SYSUSER_SUCCESS: {
      return {
        ...state,
      }
    }
    case CREATE_LOGIN_SYSUSER_ERROR: {
      return {
        ...state,
      }
    }
    case GET_LIST_SYSUSER: {
      return {
        ...state,
      }
    }
    case GET_LIST_SYSUSER_SUCCESS: {
      return {
        ...state,
        SysUserList: action.data,
      }
    }
    case GET_LIST_SYSUSER_DEFAULT_SUCCESS: {
      return {
        ...state,
        SysUserDefaultList: action.data,
      }
    }
    case GET_LIST_SYSUSER_ERROR: {
      return {
        ...state,
      }
    }
    case SYSUSER_PROFILE_SUCCESS: {
      return {
        ...state,
        SysUserRecord: action.payload
      }
    }
    case UPDATE_SYSUSER_SUCCESS: {
      const newList = state.SysUserList.map(function (item) {
        return item.id == action.payload.id ? action.payload : item
      })
      return {
        ...state,
        SysUserList: newList,
      }
    }
    case UPDATE_SYSUSER_ERROR: {
      return {
        ...state,
      }
    }
    case GET_AVATAR_PROFILE_SUCCESS: {
      return {
        ...state,
        avatar: action.avatar
      }
    }
    default:
      return state
  }
}
export default SysuserReducer
