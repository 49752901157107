import {
  GET_LIST_ACCOUNT,
  GET_LIST_ACCOUNT_SUCCESS,
  GET_LIST_ACCOUNT_DEFAULT_SUCCESS,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  GET_BY_ID_ACCOUNT,
  GET_BY_ID_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_ERROR,
  UPDATE_ACCOUNT,
  GET_LIST_ACTIVE_ACCOUNTS_SUCCESS,
  GET_LIST_ACTIVE_ACCOUNTS_MASTER_SUCCESS,
  PAGE_NUMBER_ACC,
} from '../../constants/ActionTypes'
import { M_AccountItem } from '../../dto'
import { AccountStateDto } from './stateDtos'
interface ActionProps {
  id: number,
  type: string
  data: M_AccountItem[]
  Error: any,
  payload: M_AccountItem
  loading: boolean,
  error: boolean,
  CreateAlert: string
  page: number,
  tab: string
}
const INIT_STATE: AccountStateDto = {
  accountList: [],
  activeAccount: [],
  dataDefault: [],
  CreateAlert: true,
  UpdateAlert: true,
  loading: false,
  recordAcct: null,
  error: false,
  id: 0,
  page: 1,
  tab: 'Active'
}
const AccountReducer = (
  state = INIT_STATE,
  action: ActionProps
): AccountStateDto => {
  switch (action.type) {
    case PAGE_NUMBER_ACC: {
      return {
        ...state,
        page: action.page,
        tab: action.tab,
      }
    }
    case GET_LIST_ACCOUNT: {
      return {
        ...state,
        loading: true,
        accountList: [],
        activeAccount: []
      }
    }
    case GET_LIST_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        accountList: action.data,
      }
    }
    case GET_LIST_ACCOUNT_DEFAULT_SUCCESS: {
      return {
        ...state,
        loading: false,
        dataDefault: action.data,
      }
    }
    case GET_LIST_ACTIVE_ACCOUNTS_MASTER_SUCCESS: {
      const activeList = action.data.filter((x) => x.isActive == true)
      return {
        ...state,
        loading: false,
        activeAccount: activeList,
      }
    }
    case GET_LIST_ACTIVE_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        activeAccount: action.data,
      }
    }
    case GET_BY_ID_ACCOUNT: {
      return {
        ...state
      }
    }
    case GET_BY_ID_ACCOUNT_SUCCESS: {
      return {
        ...state,
        recordAcct: action.payload,
        id: action.id,
      }
    }
    case CREATE_ACCOUNT: {
      return {
        ...state,
      }
    }
    case CREATE_ACCOUNT_SUCCESS: {
      const newList = [...state.accountList]
      newList.push(action.payload)
      return {
        ...state,
        accountList: newList,
        CreateAlert: true,
      }
    }
    case CREATE_ACCOUNT_ERROR: {
      return {
        ...state,
      }
    }
    case UPDATE_ACCOUNT: {
      return {
        ...state,
      }
    }
    case UPDATE_ACCOUNT_SUCCESS: {
      const newList = state.accountList.map(function (item) { return (item.id == action.payload.id) ? action.payload : item })
      return {
        ...state,
        accountList: newList,
        UpdateAlert: true
      }
    }
    case UPDATE_ACCOUNT_ERROR: {
      return {
        ...state,
        UpdateAlert: false,
        recordAcct: action.payload,
        id: action.id,
      }
    }
    default:
      return state
  }
}
export default AccountReducer
