import {
  CREATE_HEDGEFUND_SERIES_SUCCESS,
  GET_BY_ID_HEDGEFUND_SERIES,
  GET_BY_ID_HEDGEFUND_SERIES_SUCCESS,
  GET_LIST_HEDGEFUND_SERIES,
  GET_LIST_HEDGEFUND_SERIES_DEFAULT,
  GET_LIST_HEDGEFUND_SERIES_DEFAULT_SUCCESS,
  GET_LIST_HEDGEFUND_SERIES_SUCCESS,
  GET_SERIES_BY_HEDGEFUND_ID_ERROR,
  GET_SERIES_BY_HEDGEFUND_ID_SUCCESS,
  UPDATE_HEDGEFUND_SERIES_SUCCESS,
} from '../../constants/ActionTypes'
import { HedgeFundSeriesItem } from '../../dto'
import { HFSeriesStateDto } from './stateDtos'

interface ActionProps {
  id: number
  type: string
  data: HedgeFundSeriesItem[]
  error: string
  loading: boolean
  payload: HedgeFundSeriesItem
}
const INIT_STATE: HFSeriesStateDto = {
  loading: false,
  HfSeriesList: [],
  dataDefault: [],
  recordHFSeries: null,
}
const HedgeFundSeriesReducer = (
  state = INIT_STATE,
  action: ActionProps
): HFSeriesStateDto => {
  switch (action.type) {
    case GET_LIST_HEDGEFUND_SERIES: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_LIST_HEDGEFUND_SERIES_SUCCESS: {
      const dataSeries = action?.data?.map((item) => {
        const { fundSeries } = item
        return fundSeries
      })
      return {
        ...state,
        loading: false,
        HfSeriesList: dataSeries[0],
      }
    }
    case GET_LIST_HEDGEFUND_SERIES_DEFAULT: {
      return {
        ...state,
      }
    }
    case GET_LIST_HEDGEFUND_SERIES_DEFAULT_SUCCESS: {
      const dataSeries = action.data.map((item) => {
        const { fundSeries } = item
        return fundSeries
      })
      return {
        ...state,
        loading: false,
        dataDefault: dataSeries[0],
      }
    }
    case CREATE_HEDGEFUND_SERIES_SUCCESS: {
      const newList = [...state.HfSeriesList]
      newList.push(action.payload)
      return {
        ...state,
        HfSeriesList: newList,
      }
    }
    case GET_BY_ID_HEDGEFUND_SERIES: {
      return {
        ...state,
        recordHFSeries: null,
      }
    }
    case GET_BY_ID_HEDGEFUND_SERIES_SUCCESS: {
      return {
        ...state,
        recordHFSeries: action.payload,
        // id: action.id,
      }
    }
    case GET_SERIES_BY_HEDGEFUND_ID_SUCCESS: {
      return {
        ...state,
        HfSeriesList: action.data,
      }
    }
    case GET_SERIES_BY_HEDGEFUND_ID_ERROR: {
      return {
        ...state,
      }
    }
    case UPDATE_HEDGEFUND_SERIES_SUCCESS: {
      const newList = state.HfSeriesList.map(function (item) {
        return item.id == action.payload.id ? action.payload : item
      })
      return {
        ...state,
        HfSeriesList: newList,
      }
    }
    default:
      return state
  }
}
export default HedgeFundSeriesReducer
