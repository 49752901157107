import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'

interface iProps {
  match: {
    url: string
  }
}
const Analyze = ({ match }: iProps): JSX.Element => (
  <Switch>
    <Route
      exact
      path={`${match.url}/investors`}
      component={asyncComponent(
        () => import('../../components/DVIEW_DataView/A_Investor/InvestorView')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/investor/:investorId`}
      component={asyncComponent(
        () => import('../../components/DVIEW_DataView/A_Investor/ViewById')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/hfs`}
      component={asyncComponent(
        () =>
          import('../../components/DVIEW_DataView/A_HedgeFund/HedgeFundViewer')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/hf/:id`}
      component={asyncComponent(
        () => import('../../components/DVIEW_DataView/A_HedgeFund/ViewById')
      )}
    />{' '}
    <Route
      exact
      path={`${match.url}/accts`}
      component={asyncComponent(
        () =>
          import(
            '../../components/DVIEW_DataView/A_AccountViewer/AccountViewer'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/acct/:accountId`}
      component={asyncComponent(
        () =>
          import(
            '../../components/DVIEW_DataView/A_AccountViewer/AccountViewerDetail'
          )
      )}
    />
    <Route
      exact
      path={`${match.url}/marketData`}
      component={asyncComponent(
        () =>
          import(
            '../../components/DVIEW_DataView/A_MarketData/MarketDataViewer'
          )
      )}
    />
  </Switch>
)
export default Analyze
